import { FunctionComponent, memo, useEffect } from 'react'
import styled, { css } from 'styled-components'
import YouTube from 'react-youtube'
import { YoutubeVideoProps } from './types'

import useIsInRecipeContent from '@hooks/useIsInRecipeContent'
import { printCSS } from '@utils/style'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'
import CMPPlaceholder from '@components/CMPPlaceholder'

import useVideoRefs from '@hooks/useVideoRefs'
import { VIDEO_TYPES } from '@widgets/Video/utils'
import useIsInArticleBodyContent from '@hooks/useIsInArticleBodyContent'
import useIsInCueLiveContent from '@hooks/useIsInCueLiveContent'

export interface CMPAwareYoutubeVideoProps extends YoutubeVideoProps {
  bypassCMP?: boolean
}

const opts = {
  playerVars: {
    rel: 0,
    autoplay: 0,
    showinfo: 0,
    controls: 1,
    playsinline: 1,
    enablejsapi: 1,
  },
}

const YoutubeVideoWrapper = styled.div<{
  shouldStretch: boolean
}>`
  ${({
    theme: {
      measures: { stretchWidthOnMobile },
    },
    shouldStretch,
  }) => css`
    ${printCSS(css`
      display: none;
    `)}
    ${shouldStretch && stretchWidthOnMobile()};
  `}
`

const StyledYoutubeMainElementContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
`

const StyledYoutubeMainElement = styled(YouTube)`
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const YoutubeVideo: FunctionComponent<YoutubeVideoProps> = ({
  videoId,
  widgetId,
}) => {
  const isInArticleBody = useIsInArticleBodyContent()
  const isInRecipe = useIsInRecipeContent()
  const isInCueLive = useIsInCueLiveContent()
  const { addVideoPlayer, removeVideoPlayer, activateVideoPlayer } =
    useVideoRefs()

  const shouldStretch = isInArticleBody && !isInRecipe && !isInCueLive

  useEffect(() => {
    return () => {
      removeVideoPlayer(widgetId)
    }
  }, [removeVideoPlayer, widgetId])

  if (!videoId) {
    return null
  }

  return (
    <YoutubeVideoWrapper shouldStretch={shouldStretch}>
      <StyledYoutubeMainElementContainer>
        <StyledYoutubeMainElement
          id={widgetId}
          videoId={videoId}
          opts={opts}
          onReady={() => {
            addVideoPlayer({
              type: VIDEO_TYPES.YOUTUBE,
              widgetId,
            })
          }}
          onPlay={() => {
            activateVideoPlayer(widgetId)
          }}
        />
      </StyledYoutubeMainElementContainer>
    </YoutubeVideoWrapper>
  )
}

const CMPAwareYoutubeVideo: FunctionComponent<CMPAwareYoutubeVideoProps> = ({
  bypassCMP,
  ...restOfProps
}) =>
  useCMPCookieCategories('allCategoriesEnabledChanged') ? (
    <YoutubeVideo {...restOfProps} />
  ) : (
    <CMPPlaceholder />
  )

const MemoizedCMPAwareYoutubeVideo = memo(CMPAwareYoutubeVideo)
MemoizedCMPAwareYoutubeVideo.displayName = 'MemoizedCMPAwareYoutubeVideo'

export default MemoizedCMPAwareYoutubeVideo
