const translationsFR = {
  adsTitle: 'Publicité',
  and: 'et',
  'arrowButton.collapseMenu': 'Moins',
  'arrowButton.expandMenu': 'Plus',
  'articleSummary.collapseContent': 'Cacher le résumé',
  'articleSummary.disclaimerFeedback': 'Envoyer un retour',
  'articleSummary.disclaimerMoreInfo': 'Plus d’infos',
  'articleSummary.disclaimerText':
    'L’intelligence artificielle de Blick est encore en cours d’apprentissage et peut faire des erreurs.',
  'articleSummary.expandContent': 'Montrer le résumé',
  'articleSummary.text':
    'Le résumé des articles de Blick+ est réservé aux abonnés. Merci de vous connecter si vous êtes concerné.',
  'articleSummary.title': 'L’actu en un clic',
  back: 'Retour',
  'blickBites.back': 'Retour',
  'blickBites.endOfFastlaneTitle': 'Vous avez atteint la fin',
  'blickBites.onboarding.title': 'Faites glisser vers le haut',
  'blickBites.onboarding.text': 'pour voir plus de vidéos',
  'blickBites.onboarding.letsGo': 'C’est parti!',
  'blickBites.readMore': 'En savoir plus',
  'blickBites.share': 'Partager',
  'blickPlus.linkSharing.availableSharesText': '',
  'blickPlus.linkSharing.copyButton.copied': '',
  'blickPlus.linkSharing.copyButton.copy': '',
  'blickPlus.linkSharing.copyButton.generate': '',
  'blickPlus.linkSharing.errorText': '',
  'blickPlus.linkSharing.hasRunOutOfSharesText': '',
  'blickPlus.linkSharing.linkToAbos': '',
  'blickPlus.linkSharing.linkToFaqs': '',
  'blickPlus.linkSharing.loadingLabel': '',
  'blickPlus.linkSharing.notSubscribedText': '',
  'blickPlus.linkSharing.title': '',
  'blickTVStory.chapterTitle': 'Toutes les séquences du live',
  'blickTVStory.filler': 'D’autres parties suivront plus tard',
  'blickTVStory.goToLive': 'Revenir au live',
  'blickTVStory.live': 'LIVE',
  'blickTVStory.restartCurrentChapter': 'Revenir au début de la séquence',
  close: 'Fermer',
  'cmp.placeholder.description':
    'Pour afficher les contenus de prestataires tiers (Twitter, Instagram), vous devez autoriser tous les cookies et le partage de données avec ces prestataires externes.',
  'cmp.placeholder.primaryButton': 'Charger le contenu',
  'cmp.placeholder.secondaryButton': "Plus d'informations",
  'cmp.placeholder.title': 'Contenu tiers',
  'commenting.answer': 'Répondre',
  'commenting.answerPlaceholder': 'Ecrivez une réponse...',
  'commenting.answerPlaceholderEmailOnly':
    'Complétez votre profil ici pour répondre...',
  'commenting.answerPlaceholderNotLoggedIn':
    'Connectez-vous et écrivez une réponse...',
  'commenting.authorType.admin': 'Administrateur',
  'commenting.authorType.admin.female': 'Administratrice',
  'commenting.authorType.journalist': 'Journaliste',
  'commenting.authorType.journalist.female': 'Journaliste',
  'commenting.authorType.moderator': 'Modérateur',
  'commenting.authorType.moderator.female': 'Modératrice',
  'commenting.authorType.user': 'Utilisateur',
  'commenting.blickPlusInfoBox.button': '',
  'commenting.blickPlusInfoBox.loginHint': '',
  'commenting.blickPlusInfoBox.loginLinkButton': '',
  'commenting.blickPlusInfoBox.text': '',
  'commenting.blickPlusInfoBox.title': '',
  'commenting.cancel': 'Annuler',
  'commenting.commentPlaceholder': 'Ecrivez un commentaire...',
  'commenting.commentPlaceholderEmailOnly':
    'Complétez votre profil ici pour commenter...',
  'commenting.commentPlaceholderNotLoggedIn':
    'Connectez-vous et écrivez un commentaire...',
  'commenting.commentingRulesLinkText': 'Сommentaires.',
  'commenting.commentingRulesText': 'La charte des ',
  'commenting.delete': 'Supprimer',
  'commenting.deleteConfirmation':
    'Voulez-vous vraiment supprimer ce commentaire?',
  'commenting.deletedUserName': 'Cet utilisateur a supprimé le compte',
  'commenting.loadMoreAnswers': 'Montrer plus de réponses',
  'commenting.loadMoreComments': 'Afficher plus de commentaires',
  'commenting.noAvailableComments':
    'Aucun commentaire disponible pour le moment.',
  'commenting.numberOfCharactersLeft':
    'Encore {{numberOfCharacters}} caractères restants',
  'commenting.numberOfCharactersOverflow':
    '{{numberOfCharacters}} trop de caractères',
  'commenting.oneCharacterLeft': '1 caractère restant',
  'commenting.oneCharacterOverflow': '1 caractère en trop',
  'commenting.postCommentSentMessage':
    'Merci beaucoup, votre commentaire va être examiné.',
  'commenting.postSubmit': 'Commenter',
  'commenting.reaction.critical': 'Je suis sceptique',
  'commenting.reaction.dislike': 'Je ne suis pas d’accord',
  'commenting.reaction.happy': 'Marrant!',
  'commenting.reaction.like': ' Je suis d’accord',
  'commenting.reaction.sad': 'Cela me rend triste',
  'commenting.report': 'Signaler',
  'commenting.reportTitle': 'Motif du signalement',
  'commenting.reportedMessage': 'Merci pour votre message.',
  'commenting.title.top': 'Voix sélectionnées',
  'commenting.to': 'à',
  'config.gdprTitle': 'Protection des données',
  'cueLive.emptyTickerText': 'Le live ticker n’a pas encore commencé.',
  'cueLive.endIndicator': 'Fin du Live',
  'cueLive.loadMoreEntries': 'Autres entrées',
  'cueLive.loadingEntry': 'Chargement de l’entrée Liveticker',
  'cueLive.pinnedPost': 'Article top',
  dossier: 'Dossier',
  'errorPage.errorStatus': 'Erreur {{errorStatus}}',
  'errorPage.helpMessage':
    'Utilisez la navigation ci-dessus pour trouver cet article ou un autre.',
  'errorPage.pageNotFound': 'Malheureusement, cette page n’existe pas',
  'errorPage.unknownErrorMessage': 'Une erreur s’est produite',
  'feedback.foundMistake': 'Vous avez trouvé une erreur?',
  'feedback.mailtoBody':
    "Merci de décrire l'erreur:\n\n\n\n____ \n{{articleUrl}}",
  'feedback.mailtoSubject':
    'Signalement d’erreur dans l’article "{{articleTitle}}"',
  'feedback.reportNow': 'Signalez-la',
  'footer.ringierFooterTitle': 'Plus de Ringier',
  'formIO.loginRequiredButtonText': 'Se connecter',
  'formIO.loginRequiredText': 'Veuillez vous inscrire pour participer!',
  'formIO.noEmailOnlyRequiredButtonText': 'Vers le profil',
  'formIO.noEmailOnlyRequiredText': 'Complétez votre profil pour participer',
  'formIO.submissionMessage': 'Nous vous remercions de votre participation!',
  'gallery.photo': 'Photo',
  googleNewsTitle: 'Suivez-nous',
  highlights: 'Mes meilleurs articles',
  lastUpdated: 'Dernière mise à jour',
  less: 'Moins',
  lessThanMinute: 'il y a 1 minute',
  lessThanNMinutes: 'il y a {{diffMinutes}} minutes',
  'liveEvent.cancelled': 'Annulé',
  'liveEvent.dataSkiStageTypes.ABFAHRT': 'Descente',
  'liveEvent.dataSkiStageTypes.GESAMT': 'Total',
  'liveEvent.dataSkiStageTypes.KOMBINATION': 'Combinaison',
  'liveEvent.dataSkiStageTypes.RIESENSLALOM': 'Slalom géant',
  'liveEvent.dataSkiStageTypes.SLALOM': 'Slalom',
  'liveEvent.dataSkiStageTypes.SUPERG': 'Super G',
  'liveEvent.finished': 'Terminé',
  'liveEvent.lap': 'Tour',
  'liveEvent.live': 'EN DIRECT',
  'liveEvent.skiFirstRun': '1er Exécuter',
  'liveEvent.skiSecondRun': '2e Exécuter',
  'login.logout': 'Déconnexion',
  'login.myAccount': 'Mon compte',
  'lotto.chf': 'CHF',
  'lotto.drawingFrom': 'En s’inspirant de {{drawDate}}',
  'lotto.luckyNumber': '+ numéro chanceux',
  'lotto.nextDraw': 'Prochain tirage: {{nextDate}}',
  'lotto.nextJackpot': 'Prochain jackpot',
  'lotto.nowPlaying': 'Jouer maintenant',
  'lotto.numberOfWinners': 'Nombre de gagnants',
  'lotto.prizeCategories': 'Rangs gagnants',
  'lotto.profit': 'Gain',
  'matchStatus.afterExtraTime': 'Après prolongation',
  'matchStatus.afterPenalty': 'Après la séance de tirs au but',
  'matchStatus.calledOff': 'Annulé',
  'matchStatus.cancelled': 'Interrompu',
  'matchStatus.ended': 'Terminé',
  'matchStatus.interrupted': 'Interruption',
  'matchStatus.overtime': 'Prolongation',
  'matchStatus.penaltiesRunning': 'Tirs au but',
  more: 'plus',
  newest: 'Mes derniers articles',
  next: 'Suite',
  published: 'Publié',
  'published.at': 'à',
  'recipe.cookTime': 'Temps de cuisson',
  'recipe.ingredients': 'Ingrédients',
  'recipe.instructions': 'Instructions',
  'recipe.nutrition': 'Calories par portion',
  'recipe.portions': 'Portions',
  'recipe.prepTime': 'Temps de préparation',
  'recipe.step': 'Étape',
  'recommendation.title': 'Ceci pourrait aussi vous intéresser',
  'scoreboard.versus': 'Vs',
  'search.defaultButtonTitle': 'Rechercher',
  'search.description':
    'Enregistrez-vous gratuitement et bénéficiez d’un accès illimité à tous les contenus de Blick.ch.',
  'search.errorMessage':
    'La recherche n’est actuellement pas disponible. Veuillez réessayer dans quelques instants.',
  'search.input': 'Recherche...',
  'search.loading': 'Recherche en cours...',
  'search.moreInfoLabel': 'En savoir plus',
  'search.noResultsExplanation':
    'Malheureusement, nous n’avons pas trouvé de résultats correspondants.',
  'search.of': 'de',
  'search.onlyOneSearchResult': 'Résultat de la recherche',
  'search.searchResults':
    'Nous avons {{totalElements}} résultats correspondant à votre recherche.',
  'search.singleSearchResult': 'Nous avons 1 résultat à votre recherche',
  shareMessage: 'Partagé via Blick',
  'social.copied': 'Lien copié',
  'social.copy': 'Copier le lien',
  'social.copyToast': 'Lien copié',
  'social.share': 'Partager',
  'sportTable.goalDiffTotal': 'DB.',
  'sportTable.matchesTotal': 'J.',
  'sportTable.name': 'Équipe',
  'sportTable.pointsTotal': 'PT.',
  submit: 'Envoyer',
  team: 'Mon équipe',
  'topList.commentCount': '',
  'topStories.defaultTitle': 'Top Topics',
  'video.blickPlus.buttonLabel': '',
  'video.blickPlus.mainText': '',
  'video.captionsDisabled': 'Sous-titres non disponibles',
  'video.captionsOff': 'Sous-titres désactivé',
  'video.captionsOn': 'Sous-titres activé',
  'video.error.browserNonRecoverable.text':
    'La vidéo n’est pas prise en charge par <br/> votre navigateur.',
  'video.error.browserNonRecoverable.title':
    'Veuillez essayer un autre navigateur',
  'video.error.defaultNonRecoverable.text':
    'Veuillez réessayer de charger la vidéo <br/> ou revenir plus tard.',
  'video.error.defaultNonRecoverable.title':
    'Une erreur inattendue s’est produite',
  'video.error.geolocationNonRecoverable.text':
    'La vidéo n’est pas disponible <br/> dans votre pays.',
  'video.error.geolocationNonRecoverable.title':
    'Désolé, la vidéo n’est pas disponible',
  'video.error.loadingNonRecoverable.text':
    'Allez tranquillement vous chercher un <br/> café ou un thé et réessayez plus tard.',
  'video.error.loadingNonRecoverable.title':
    'La vidéo ne peut pas être chargée',
  'video.error.loadingRecoverable.text':
    'La vidéo n’a pas pu être chargée. <br/> Veuillez réessayer.',
  'video.error.loadingRecoverable.title': 'Quelque chose s’est mal passé',
  'video.error.networkNonRecoverable.text':
    'Il y a malheureusement des problèmes de <br/> connexion. Veuillez réessayer plus tard.',
  'video.error.networkNonRecoverable.title': 'Pas d’Internet disponible',
  'video.error.networkRecoverable.text':
    'Veuillez vérifier votre connexion et <br/> réessayer de charger la vidéo.',
  'video.error.networkRecoverable.title': 'Votre connexion Internet a échoué',
  'video.error.playingNonRecoverable.text':
    'Allez tranquillement vous chercher un <br/> café ou un thé et réessayez plus tard.',
  'video.error.playingNonRecoverable.title': 'La vidéo ne peut pas être lue',
  'video.fullscreenOff': 'Plein écran désactivé',
  'video.fullscreenOn': 'Plein écran activé',
  'video.liveLabel': 'Live',
  'video.moreVideosOff': 'Recommandation de vidéos désactivée',
  'video.moreVideosOn': 'Recommandation de vidéos activée',
  'video.moreVideosUnavailable': 'Recommandation de vidéos non disponibles',
  'video.pipOn': 'Lecteur réduit activé',
  'video.reload': 'RECHARGER',
  'video.replay': 'Replay',
  'video.resumePlayback': 'Reprendre la lecture de la vidéo?',
  'video.resumePlayback.continue': 'Continuer',
  'video.resumePlayback.replay': 'Redémarrer',
  'video.trailer': '',
  'video.trailer.blickPlus.mainText': '',
  'video.trailer.blickPlus.title': '',
  'video.unmuteCTAButton': 'Avec son?',
  'video.withVideoLabel': 'Avec vidéo',
  'sectionSummary.disclaimerText':
    'L’intelligence artificielle de Blick est encore en cours d’apprentissage et peut faire des erreurs.',
  'sectionSummary.disclaimerMoreInfo': 'Plus d’infos',
  'sectionSummary.disclaimerFeedback': 'Envoyer un retour',
  'sectionSummary.request.title': 'L’actu en un clic',
  'sectionSummary.request.text':
    'Obtenez un résumé généré automatiquement des news les plus importantes – des actualités romandes, suisses et internationales aux résultats sportifs.',
  'sectionSummary.request.primaryButton': 'Créer un résumé',
  'sectionSummary.request.error':
    'Désolé, cela n’a pas fonctionné. Veuillez réessayer plus tard.',
  'sectionSummary.request.error.primaryButton': 'Recharger',
  'sectionSummary.sources.title': 'Sources',
  'sectionSummary.sources.subtitle.singular':
    'Contenu extrait d’un seul article de Blick',
  'sectionSummary.sources.subtitle.plural':
    'Contenu extrait de {{totalElements}} articles de Blick',
} as const

export type TranslationsFR = typeof translationsFR

export default translationsFR
