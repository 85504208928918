import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { tabletCSS, mobileCSS } from '@measures/responsive'
import {
  getTOISportsFooterMinHeightForSportAndViewport,
  HeroModuleAPIProps,
} from '@widgets/HeroModule'
import useClientViewportType from '@hooks/useClientViewportType'

export interface RelatedContentWrapperDesktopProps {
  typeOfSport: HeroModuleAPIProps['typeOfSport']
  hasNewsticker: boolean
  children: ReactNode
}

export type StyledRelatedContentWrapperDesktopProps = Omit<
  RelatedContentWrapperDesktopProps,
  'children'
>

const StyledRelatedContentWrapperDesktop = styled.div<StyledRelatedContentWrapperDesktopProps>`
  ${({
    typeOfSport,
    hasNewsticker,
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    position: absolute;
    z-index: 1;
    bottom: calc(
      24px +
        ${hasNewsticker
          ? '85px + 24px'
          : typeOfSport
            ? `${getTOISportsFooterMinHeightForSportAndViewport(
                typeOfSport,
                'desktop'
              )} + 24px`
            : '0px'}
    );
    right: 15px;
    width: 300px;

    background-color: rgba(17, 17, 17, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 4px;
    padding: ${spacing16};

    ${tabletCSS(css`
      display: none;
    `)};

    ${mobileCSS(css`
      display: none;
    `)};
  `};
`

const RelatedContentWrapperDesktop: FunctionComponent<
  RelatedContentWrapperDesktopProps
> = ({ hasNewsticker, typeOfSport, children }) =>
  !['server', 'desktop'].includes(useClientViewportType()) ? null : (
    <StyledRelatedContentWrapperDesktop
      hasNewsticker={hasNewsticker}
      typeOfSport={typeOfSport}>
      {children}
    </StyledRelatedContentWrapperDesktop>
  )

export default RelatedContentWrapperDesktop
